import './scripts/common';
import {onFind} from "@elements/init-modules-in-scope";

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import * as lightbox from '@elements/lightbox';
lightbox.init();

import Collapse from 'bootstrap/js/dist/collapse';
onFind('[data-bs-toggle="collapse"]',function (element) {
    new Collapse(element)
});

import * as affix from '@elements/affix';
affix.init({mediaQuery: '(min-width: 768px)'});

import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init({
    arrowIconClass:"icon icon-slide-right"
});

import * as dropdownPersist from './scripts/dropdown-persist';
dropdownPersist.init();

import * as multipleSelectDropdown from './scripts/multiselect-dropdown';
multipleSelectDropdown.init();

import Tab from 'bootstrap/js/dist/tab';
onFind('[data-bs-toggle="tab"]',function (element) {
    new Tab(element)
});

import * as shariff from './scripts/shariff';
shariff.init();

import * as lazyIframe from '@elements/lazy-iframe';
lazyIframe.init();

import * as anchorNav from '@elements/anchor-nav/bootstrap-5';
anchorNav.init(
    {
        renderItem: function (text, anchor) {
            return '<li class="nav-item anchor-nav__list-item list-inline-item js-anchor-nav__item"><a class="js-anchor-nav__link nav-link anchor-nav__link js-smoothscroll__link" data-scroll-offset="100" href="#' + anchor + '"><span class="anchor-nav__title">' + text + '</span></a></li>';
        },
        smoothScroll: true,
        scrollSpy: false,
        scrollSpyMethod: 'position',
        scrollSpyTarget: '.js-anchor-nav',
        scrollSpyOffset: 100
    },
    {
        list: '.js-anchor-nav__list',
        item: '.js-anchor-nav__item'
    }
);

import {find} from "@elements/dom-utils";
import ScrollSpy from './libs/bootstrap/scrollspy';
onFind('.js-anchor-nav', function (element) {
    new ScrollSpy(document.body, {
        target: find('.js-anchor-nav'),
        offset: 100,
        method: 'position'
    });
});

import * as anchorNavExtension from './scripts/anchor-nav-extension';
anchorNavExtension.init();
import {onFind} from "@elements/init-modules-in-scope";
import {findAll, on} from '@elements/dom-utils';

import * as toc from './toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as customCursor from './custom-cursor';
customCursor.init();

import Collapse from 'bootstrap/js/dist/collapse';
onFind('[data-bs-toggle="collapse"]',function (element) {
    new Collapse(element)
});

import * as renderTemplate from './render-template';
renderTemplate.init();

import * as tableResponsive from './table-responsive';
tableResponsive.init();

import * as nav from './nav';
nav.init();

import * as playVideo from './play-video';
playVideo.init();

import * as formTracking from './form-tracking';
formTracking.init();

import * as tracking from "@elements/tracking";
let options = {}; // optional options
tracking.initGtmEventTracking(options); // for GTM tracking

import * as affix from '@elements/affix';
affix.init();

import * as floatingLabels from '@elements/floating-labels';
floatingLabels.init();

import * as fadeIn from './fade-in';
fadeIn.init();

import * as formValidation from './form-validation';
formValidation.init();

import * as scrollHint from './scroll-hint';
scrollHint.init();

import * as centerActiveTab from './center-active-tab';
centerActiveTab.init();

import * as actionChanger from '@elements/action-changer';
actionChanger.init();

import * as infoModal from './info-modal';
infoModal.init();

import * as skipLinks from '@elements/skip-links';
skipLinks.init();


import Dropdown from 'bootstrap/js/dist/dropdown';
onFind('[data-bs-toggle="dropdown"]',function (element) {
    new Dropdown(element);
});

import * as accessiway from './accessiway';
accessiway.init();

import * as consentOverlay from '@elements/consent-overlay';
consentOverlay.init();

export function init () {
    (function () {
        var s = document.createElement('script'),
            e = !document.body ? document.querySelector('head') :
                document.body;
        s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
        s.async = true;
        s.onload = function () {
            acsbJS.init({
                statementLink: '',
                footerHtml: '<a  href="https://www.accessiway.com/" target="_blank">AccessiWay. The Web Accessibility Solution</a>',
                hideMobile: false,
                hideTrigger: false,
                language: _config.lang,
                position: 'right',
                leadColor: '#cc0429',
                triggerColor: '#cc0429',
                triggerRadius: '0',
                triggerPositionX: 'right',
                triggerPositionY: 'bottom',
                triggerIcon: 'people',
                triggerSize: 'medium',
                triggerOffsetX: 0,
                triggerOffsetY: 0,
                mobile: {
                    triggerSize: 'small',
                    triggerPositionX: 'right',
                    triggerPositionY: 'bottom',
                    triggerOffsetX: 0,
                    triggerOffsetY: 0,
                    triggerRadius: '0'
                }
            });
        };
        e.appendChild(s);
    }())

}
"use strict";

import {closest,trigger, findAllIn, on} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

export function init () {

    onFind('.js-multiple-select-dropdown', function (dropdown) {
        let inputs = findAllIn('input', dropdown);

        /*
            needed to prevent change event
            for multiselect
        */
        inputs.map(function(item) {
            on('change', function(evt) {
                evt.preventDefault();
                evt.stopImmediatePropagation();
            }, item)
        })

        dropdown.addEventListener('hide.bs.dropdown', function (element) {
            let dropdown = element.target;
            let form = closest('form', dropdown);

            console.log('dropdown.getAttribute(\'data-multiple-select-dropdown-submit-on-hide\')',dropdown.getAttribute('data-multiple-select-dropdown-submit-on-hide'))
            if (dropdown.getAttribute('data-multiple-select-dropdown-submit-on-hide') === 'true'){
                trigger('submit',form);

            }
        });

    });

}